



import { Component, Vue, Emit } from 'vue-property-decorator';
import auth from '@/store/auth';
import { CourseResponseCourses, CoursePostOutlineOutline } from '../../../../../types/typescript-axios/api';
import VueRouter, { Route } from 'vue-router';
import axios, {AxiosResponse, AxiosError} from 'axios';
import StepNavi from '@/components/contents/stepNavi/StepNavi.vue';
import Error from '@/components/contents/Error.vue';
import CourseBox from '@/components/contents/course/CourseBox.vue';
import CourseFormTermsBox from '@/components/contents/course/CourseFormTermsBox.vue';
import store from '@/store';
import VeeValidate from '@/plugins/vee-validate';
import { load } from 'recaptcha-v3';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

async function getDatas(to: Route) {
  let status: number = 200;
  const url: string = '/wp-json/moc/account';

  const $to: any = to;

  const paramsName: any = $to.params.courceId;

  const accountData = await axios.get<AxiosResponse>(url);
  status = accountData.status;

  return {
    status,
    accountData,
    paramsName,
    $to,
  };
}

@Component({
  components: {
    StepNavi,
    CourseBox,
    CourseFormTermsBox,
    Error,
  },
  mixins: [ VeeValidate ],
})
export default class CourseDetailWaiting extends Vue {
  private get stepNavi(): string {
    const $parent: any = this.$parent;
    return $parent.stepNavi;
  }

  // 登録を確認後のエラー文
  private responseError: boolean = false;
  // 入力時のエラー文
  private errorMessage: boolean = false;
  private paramsName: any = 0;
  private paramsKey: string = '';
  private courseAgree: string = '0';
  private accountData: any = {};
  private status = 200;

  get existPic(): string {
    if (this.accountData.exist_pic === '0') {
      return 'はい';
    } else if (this.accountData.exist_pic === '1') {
      return 'いいえ';
    } else {
      return '未登録';
    }
  }

  private get outline(): CoursePostOutlineOutline[] {
    const result: CoursePostOutlineOutline[] = [...this.courseDetail.data2.outline];
    result.unshift({
      header: '講座名',
      data: this.courseDetail.data2.title.rendered,
    });
    return result;
  }

  private get accountInfo(): CoursePostOutlineOutline[] {
    const result: CoursePostOutlineOutline[] = [
      {
        header: 'お名前',
        data: `${this.accountData.sei} ${this.accountData.mei} 様`,
      },
      {
        header: '企業名',
        data: `${this.accountData.comp_nm}`,
      },
      {
        header: 'メールアドレス',
        data: `${this.accountData.email}`,
      },
      {
        header: '緊急ご連絡先',
        data: `${this.accountData.tel1}-${this.accountData.tel2}-${this.accountData.tel3}`,
      },
      {
        header: '広報用写真への掲載を承諾いただけますか？※',
        data: `${this.existPic}<br>（※会員限定Webサイトにて講座の模様などをご紹介させていただきます。）`,
      },
    ];
    return result;
  }

  private async onSubmitCancel() {
    const $observer: any = this.$refs.observer;
    $observer.reset();
    $observer.validate().then(async (result: boolean) => {
      if (result && this.courseAgree === '0') {
        const $param: any = {};
        const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_KEY);
        const token = await recaptcha.execute('homepage');
        $param.recaptchaToken = token;
        $param.token = auth.state.token;
        $param.cancel = true;
        const csid = this.courseDetail.data2.acf?.postgres_id;
        const key = this.paramsKey;
        const nonce = await this.$store.dispatch('nonce/getData', null , {root: true});
        const query = new URLSearchParams(nonce);
        const url: string = `/wp-json/moc/course/${csid}/waiting/${key}/reserve?cancel&${query}`;
        axios.put<AxiosResponse>(url, $param)
        .then((response: any) => {
          this.$router.push(`/course/${this.paramsName}/waiting/${key}/complete/?cancel`);
        }, (e: AxiosError) => {
          store.commit('window/setNotFound', 403);
          this.$router.push(`/course/${this.paramsName}/waiting/${key}/error/`);
        });
      } else {
        this.errorMessage = true;
        Vue.nextTick()
        .then(() => {
          const errorDome: any = this.$refs.errorMessage;
          window.scrollTo({
            top: window.pageYOffset + errorDome.getBoundingClientRect().top,
            behavior: 'smooth',
          });
        });
      }
    });
  }

  private async onSubmit() {
    const $observer: any = this.$refs.observer;
    $observer.reset();
    $observer.validate().then(async (result: boolean) => {
      if (result && this.courseAgree === '0') {
        const $param: any = {};
        const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_KEY);
        const token = await recaptcha.execute('homepage');
        $param.recaptchaToken = token;
        $param.token = auth.state.token;
        const csid = this.courseDetail.data2.acf?.postgres_id;
        const key = this.paramsKey;
        const nonce = await this.$store.dispatch('nonce/getData', null , {root: true});
        const query = new URLSearchParams(nonce);
        const url: string = `/wp-json/moc/course/${csid}/waiting/${key}/reserve?${query}`;
        axios.put<AxiosResponse>(url, $param)
        .then((response: any) => {
          this.$router.push({ path: `/course/${this.paramsName}/waiting/${key}/complete/` });
        })
        .catch((e: AxiosError) => {
          store.commit('window/setNotFound', 403);
          this.$router.push(`/course/${this.paramsName}/waiting/${key}/error/`);
        });
      } else {
        this.errorMessage = true;
        Vue.nextTick()
        .then(() => {
          const errorDome: any = this.$refs.errorMessage;
          window.scrollTo({
            top: window.pageYOffset + errorDome.getBoundingClientRect().top,
            behavior: 'smooth',
          });
        });
      }
    });
  }

  private get courseDetail(): CourseResponseCourses {
    const $parent: any = this.$parent;
    return $parent.courseDetail;
  }

  private get title(): any {
    const $parent: any = this.$parent;
    return $parent.title;
  }

  @Emit()
  private dateFilter(date: string) {
    const d: Date = new Date(date);
    const dayArr = ['日', '月', '火', '水', '木', '金', '土'];
    const day = dayArr[d.getDay()];
    return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日（${day}）`;
  }

  @Emit()
  private brReplace(item: string) {
    let result = '';
    result = item.replace( /\n|\r\n|\r/g, '<br>' );
    return result;
  }

  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    const data: any = await getDatas(to);
    if (data.status === 200) {
      store.commit(
        'window/setTitle',
        `キャンセル待ち講座の参加申込み 確認・講座申込み ${store.getters['window/isTitle']} 講座 ` + process.env.VUE_APP_TITLE_SUFFIX,
      );
      next((vm: {
        accountData: any,
        paramsName: any,
        status: number,
        paramsKey: string,
      }) => {
        vm.status = data.status;
        vm.accountData = data.accountData.data;
        vm.paramsName = data.$to.params.courceId;
        vm.paramsKey = data.$to.params.key;
      });
    } else {
      store.commit(
        'window/setTitle',
        `${data.status} Error 確認・講座申込み 講座 ` + process.env.VUE_APP_TITLE_SUFFIX,
      );
      next((vm: {status: number}) => {
        vm.status = data.status;
      });
    }
  }
  private async beforeRouteUpdate(to: Route, from: Route, next: any) {
    const data: any = await getDatas(to);
    this.status = data.status;
    if (data.status === 200) {
      store.commit(
        'window/setTitle',
        `確認・講座申込み ${store.getters['window/isTitle']} 講座 ` + process.env.VUE_APP_TITLE_SUFFIX,
      );
      this.accountData = data.accountData.data;
      this.paramsName = to.params.courceId;
      this.paramsKey = to.params.key;
    } else {
      store.commit(
        'window/setTitle',
        `${data.status} Error 確認・講座申込み 講座 ` + process.env.VUE_APP_TITLE_SUFFIX,
      );
    }
    next();
  }
}
