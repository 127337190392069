






import { Component, Vue } from 'vue-property-decorator';
import KiyakuContents from '@/components/contents/kiyaku/kiyaku.vue';

@Component({
  components: {
    KiyakuContents,
  },
})
export default class CourseFormTermsBox extends Vue {
}
